.login-screen {



  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    // background: #0f0;
    min-width: 415px;
    align-self: stretch;

    @media only screen and (max-width: 720px) {
      min-width: unset;
    }


    .head {

      .App-logo {
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      h1 {
        position: relative;
        div {
          position: absolute;
          font-family: "PlayfairDisplay-SemiBold", sans-serif;
          font-size: 3rem;
          margin: 23px 0 0 0;

          // color: rgba(167, 167, 167, 0.85);

          background: linear-gradient(180deg, #FFFFFF 0%, rgba(167, 167, 167, 0.85) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          position: relative;
          z-index: 10;
        }

        &:after {
          position: absolute;
          font-family: "PlayfairDisplay-SemiBold", sans-serif;
          font-size: 3rem;
          content: "Affiliates Login";
          color: transparent;
          top: 0;
          left: 0;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5), -3px -3px 10px rgba(0, 0, 0, 0.5);
          // z-index: -1;
        }
      }
    }

    input {
      height: 50px;
    }
  }

  .input-block {
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-self: stretch;
  }

  .error {
    max-width: 300px;
    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;

    /* GS_INPUT_ERROR */

    color: #FFA2A2;

    text-shadow: 0px 0px 50px #000000;
  }

}