.statistics-screen {
  color: #fff;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  flex: 1;

  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }

  .header {
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      font-family: 'PlayfairDisplay-Bold', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 109.3%;
      /* identical to box height, or 22px */

      text-transform: capitalize;
    }

    .refresh {
      display: flex;
      flex-direction: row;
      gap: 10px;

      button {
        cursor: pointer;

        background: none;
        border: 1px #fff solid;

        font-family: 'BeVietnam-Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
        border-radius: 50px;
        padding: 10px 20px;

        color: #FFFFFF;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 10px;
          display: inline-block;


          &.active {
            svg {
              animation-name: spin;
              animation-duration: 500ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;

            }
          }

          svg {
            margin-bottom: -3px;
          }
        }

      }
    }
  }

  .title {
    align-items: center;
    justify-content: center;
    gap: 4px;

    h1 {
      margin: 0;
      font-family: 'BeVietnam-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;

      text-align: center;
      text-transform: capitalize;

      span {
        color: #16CFA3;
      }
    }

    h2 {
      margin: 0;
      font-family: 'BeVietnam-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
  }

  .table {

    flex: 1;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    position: relative;

    table {
      @media only screen and (max-width: 720px) {
        position: absolute;
        font-size: 12px;
      }

      .sales {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;

      }

      .copy {
        border: none;
        background: none;
        padding: 0;
        color: #fff;
        cursor: pointer;
      }

      .info {

        position: relative;
        cursor: pointer;
        .tooltip {
          position: absolute;
          padding: 8px;
          background-color: #ffffffdd;
          display: none;
          color: #000;
          border-radius: 5px;
          bottom: 20px;
          div {
            white-space: nowrap;
          }
        }

        &:hover {
          .tooltip {
            display: block;
          }
        }
      }

      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;

      max-width: 1085px;

      font-family: 'BeVietnam-Regular',
      sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 23px;
      margin: 0 auto;

      border: 1px #ffffff66 solid;

      thead {
        th {
          border-bottom: 1px #fff solid;
        }
      }

      th {
        color: #16CFA3;
        font-family: 'BeVietnam-Bold', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 23px;

        text-transform: uppercase;
        padding: 16px 6px;

        border-right: 1px #fff solid;

        @media only screen and (max-width: 720px) {
          font-size: 12px;
        }

        &:last-child {
          border-right: none;
        }
      }

      td,
      th {
        padding: 16px 6px;
        border-right: 1px #fff solid;

        &:last-child {
          border-right: none;
        }
      }
    }



    .status {
      text-transform: uppercase;

      &.paid {
        color: #16CFA3;
      }

      &.unpaid {
        color: #FFA2A2;
      }
    }
  }


  .summary {

    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: center;

    margin: 35px 0 25px;
    gap: 20px;

    .totals {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;

    }

    @media only screen and (max-width: 720px) {
      flex-direction: column;
    }

    .summary-list {
      // flex: 1;
      // align-self: stretch;
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;

    }
  }

  .summary-value {
    border: 1px #fff solid;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 4px 15px;
    gap: 9px;

    @media only screen and (max-width: 720px) {
      flex: 26%;
    }

    .name {
      font-family: 'BeVietnam-Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      letter-spacing: 0.07em;
      text-transform: capitalize;

      color: #FFFFFF;

      flex: 1;
      text-align: left;
    }

    .value {
      font-family: 'BeVietnam-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-transform: uppercase;

      /* GS_PRIMARY_GREEN */

      color: #16CFA3;
    }
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}