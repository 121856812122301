html,
body,
#root {
  height: 100%;
}

/* FONTS */
@font-face {
  font-family: 'BeVietnam-Light';
  src: url('assets/fonts/BeVietnam-Light.eot');
  src: url('assets/fonts/BeVietnam-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Light.svg#BeVietnam-Light') format('svg'),
    url('assets/fonts/BeVietnam-Light.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Light.woff') format('woff'),
    url('assets/fonts/BeVietnam-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Regular';
  src: url('assets/fonts/BeVietnam-Regular.eot');
  src: url('assets/fonts/BeVietnam-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Regular.svg#BeVietnam-Regular') format('svg'),
    url('assets/fonts/BeVietnam-Regular.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Regular.woff') format('woff'),
    url('assets/fonts/BeVietnam-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Medium';
  src: url('assets/fonts/BeVietnam-Medium.eot');
  src: url('assets/fonts/BeVietnam-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Medium.svg#BeVietnam-Medium') format('svg'),
    url('assets/fonts/BeVietnam-Medium.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Medium.woff') format('woff'),
    url('assets/fonts/BeVietnam-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-SemiBold';
  src: url('assets/fonts/BeVietnam-SemiBold.eot');
  src: url('assets/fonts/BeVietnam-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-SemiBold.svg#BeVietnam-SemiBold') format('svg'),
    url('assets/fonts/BeVietnam-SemiBold.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-SemiBold.woff') format('woff'),
    url('assets/fonts/BeVietnam-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Bold';
  src: url('assets/fonts/BeVietnam-Bold.eot');
  src: url('assets/fonts/BeVietnam-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Bold.svg#BeVietnam-Bold') format('svg'),
    url('assets/fonts/BeVietnam-Bold.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Bold.woff') format('woff'),
    url('assets/fonts/BeVietnam-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBold';
  src: url('assets/fonts/PlayfairDisplay-SemiBold.eot');
  src: url('assets/fonts/PlayfairDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/PlayfairDisplay-SemiBold.svg#PlayfairDisplay-SemiBold') format('svg'),
    url('assets/fonts/PlayfairDisplay-SemiBold.ttf') format('truetype'),
    url('assets/fonts/PlayfairDisplay-SemiBold.woff') format('woff'),
    url('assets/fonts/PlayfairDisplay-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('assets/fonts/PlayfairDisplay-Bold.eot');
  src: url('assets/fonts/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg'),
    url('assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype'),
    url('assets/fonts/PlayfairDisplay-Bold.woff') format('woff'),
    url('assets/fonts/PlayfairDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.App {
  text-align: center;
  height: 100%;

  background: url(./assets/images/bg.png);
  background-size: cover;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}