
/* COLORS */
$color-primary: #16CFA3;
$color-primary-hover: darken($color-primary, 5%);

$color-secondary: #FFFAE2;
$color-secondary-hover: darken($color-secondary, 5%);

$color-dark: #31394F;
$color-dark-hover: lighten($color-dark, 5%);

$color-primary-text: #31394F;
$color-secondary-text: #FFFAE2;

$color-tray-text: #313341;

$color-gold: #F9DEA0;
